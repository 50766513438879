<script setup lang="ts">
import { MenuButton, Menu, MenuItems } from "@headlessui/vue";

const profile_navigation = [
  { name: "Module" },
  { name: "Storage" },
  //{ name: "Analytics" },
  { name: "Profile" },
  //{ name: "Settings" },
];

const user = useSupabaseUser();

const profile = computed(() => user.value?.user_metadata.avatar_url);

const logout = async () => {
  await useLogout();
};
</script>

<template>
  <Menu as="div" class="relative ml-3">
    <div>
      <MenuButton class="flex rounded-full bg-white text-sm focus:outline-none">
        <span class="sr-only">Open user menu</span>
        <img
          v-if="profile"
          class="h-8 w-8 rounded-full"
          :src="profile"
          alt=""
        />
        <font-awesome-icon v-else :icon="['fas','fa-user-circle']" :size="'2x'"></font-awesome-icon>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-primary text-primary"
      >
        <NuxtLink
          v-for="item in profile_navigation"
          :to="{ name: item.name }"
          :key="item.name"
          class="block px-4 py-2 text-sm text-primary hover:bg-secondary"
          :class="[
            item.name === $route.name ? 'bg-accent' : '',
            'block px-4 py-2 text-sm text-primary',
          ]"
          role="menuitem"
          >{{ $t("navigation." + item.name.toLowerCase()) }}</NuxtLink
        >
        <div
          class="block px-4 py-2 text-sm text-primary hover:bg-red-700 hover:text-white cursor-pointer"
          @click="logout"
        >
          {{ $t("global.logout") }}
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
