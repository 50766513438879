<script lang="ts" setup>
import { MenuButton, Menu, MenuItems } from "@headlessui/vue";
import { useStagePlay } from "vue-stage-play";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
const open = ref(false);
const menuButton = ref<HTMLButtonElement | null>(null);

const { action } = useStagePlay();
const isModalVideoOpen = ref(false);
const setModalVideoOpen = (value: boolean) => {
  isModalVideoOpen.value = value;
};
</script>

<template>
  <div class="relative">
    <Menu as="div" class="relative ml-3">
      <div>
        <MenuButton
          ref="menuButton"
          class="inline-flex justify-center items-center w-full rounded-md border shadow-sm px-4 py-2 bg-primary text-sm font-medium text-primary focus:outline-none border-primary hover:bg-primary-light hover:text-primary-light"
        >
          <font-awesome-icon
            :icon="['fas', 'fa-question-circle']"
            class="mr-2"
            :size="'sm'"
          />
          <span>{{ $t("global.help_center") }}</span>
          <font-awesome-icon
            :icon="['fas', 'fa-chevron-down']"
            class="ml-2"
            :size="'sm'"
          />
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-primary text-primary"
        >
          <a
            @click="
              open = false;
              action('tour');
            "
            class="block px-4 py-2 text-sm text-primary cursor-pointer hover:bg-secondary"
            role="menuitem"
            >{{ $t("global.tuto") }}</a
          >
          <a
            href="mailto:erwan@vendeo.io"
            class="block px-4 py-2 text-sm text-primary hover:bg-secondary"
            role="menuitem"
            >{{ $t("global.contact_us") }}</a
          >
          <a
            @click="setModalVideoOpen(true)"
            class="block px-4 py-2 text-sm text-primary hover:bg-secondary cursor-pointer"
            role="menuitem"
            >{{ $t("global.video_tuto") }}</a
          >
        </MenuItems>
      </transition>
    </Menu>
    <ClientOnly>
      <Dialog
        :open="isModalVideoOpen"
        @close="setModalVideoOpen(false)"
        class="relative z-10"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-40"
          aria-hidden="true"
        ></div>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <DialogPanel
              class="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-secondary p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-primary mb-3 w-full relative"
              >
                {{ $t("global.video_tuto") }}
                <div class="absolute right-0 top-0">
                  <button
                    @click="setModalVideoOpen(false)"
                    class="text-primary hover:text-primary-light"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'fa-times']"
                      :size="'sm'"
                    />
                  </button>
                </div>
              </DialogTitle>
              <div class="relative">
                <VideoTuto />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </ClientOnly>
  </div>
</template>
