<script setup lang="ts">
import { useGeneralStore } from "@/stores/generalStore";

const store = useGeneralStore();
</script>

<template>
  <div class="flex cursor-pointer" @click="store.toggleTheme()">
    <Icon
      v-if="store.theme === 'dark'"
      name="grommet-icons:sun"
      size="1.5em"
      class="text-primary"
    />
    <Icon v-else name="ph:moon-stars-bold" size="1.5em" class="text-primary" />
  </div>
</template>
~/stores/generalStore
